import { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import Layout from 'src/Layout';
import { getPageProps } from 'src/data';
import { getEnvVariables } from 'src/data/utils/getEnvVariables';

import { useDataFetch } from 'src/hooks/useDataFetch';

import { RouteHandlerReturn } from 'src/types/model';

import { deleteNextjsCachedFiles } from 'lib/deleteNextjsCachedFiles';

import LoadingScreen from 'components/BaseHelpers/LoadingScreen';

import Page404 from './404';
import ErrorPage from './_error';

const Page = (): JSX.Element => {
  const router = useRouter();
  const { data, isLoading, error } = useDataFetch<RouteHandlerReturn>(`/api/pages${router.asPath}`);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error || !data) {
    return <ErrorPage />;
  }

  if (data.notFound) {
    return <Page404 />;
  }

  return <Layout routeData={data} />;
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context) => {
  const props = await getPageProps(context);

  // Next.js will attempt to re-generate the page:
  // - When a request comes in
  // - At most once every 30 seconds
  const revalidate = parseInt(process.env.REVALIDATE_INTERVAL || '30', 10); // In seconds

  // Check if we have a redirect (e.g. custom error page)
  if (props?.redirect) {
    await deleteNextjsCachedFiles(context);
    return {
      revalidate,
      redirect: props.redirect,
    };
  }

  if (props?.notFound) {
    await deleteNextjsCachedFiles(context);
  }

  return {
    // NextJS cannot serialize `undefined` values. Therefore we are
    // stringifying and then parsing the props to get rid of the `undefined` value
    props: {
      env: getEnvVariables(),
    },
    revalidate,
    notFound: false,
  };
};

export default Page;
