import Layout from 'src/Layout';

import { useDataFetch } from 'src/hooks/useDataFetch';

import { RouteHandlerReturn } from 'src/types/model';

import LoadingScreen from 'components/BaseHelpers/LoadingScreen';

import ErrorPage from './_error';

const Page404 = (): JSX.Element => {
  const { data, isLoading, error } = useDataFetch<RouteHandlerReturn>(`/api/pages/not-found`);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error || !data) {
    return <ErrorPage />;
  }

  return <Layout routeData={data} />;
};

export default Page404;
